import 'uni-pages';import 'uni-h5';import Vue from 'vue'
import App from './App'
import './uni.promisify.adaptor'
import util from './utils/util'
import store from './store'
import Cache from './utils/cache'
import uView from "uview-ui";
import Logger from './utils/logger'
import share from '@/libs/share.js';

Vue.config.productionTip = false
// 将 LogService 挂载到 Vue 原型上
Vue.prototype.$log = Logger;

App.mpType = 'app'
Vue.prototype.$util = util;
Vue.prototype.$Cache = Cache;
const app = new Vue({
  ...App,
  store,
  Cache,
  uView
})

// 需要在Vue.use(uView)之后执行
uni.$u.setConfig({
  // 修改$u.config对象的属性
  config: {
    // 修改默认单位为rpx，相当于执行 uni.$u.config.unit = 'rpx'
    unit: 'rpx'
  },
  // 修改$u.props对象的属性
  props: {
    // 修改radio组件的size参数的默认值，相当于执行 uni.$u.props.radio.size = 30
    text: {
      size: 30
    },
    navbar: {
      leftIconSize: 40
    },
    upload: {
      width: 160,
      height: 160,
    },
    form: {
      labelWidth: 90,
    },
    textarea: {
      height: 140,
    },
    picker: {
      itemHeight: 88,
    },
    checkbox: {
      iconSize: 24,
      labelSize: 28,
      size: 36,
    },
    checkboxGroup: {
      iconSize: 24,
      labelSize: 28,
      size: 36,
      activeColor: '#00c8c8',
    },
    divider: {
      textSize: 28,
    },
    radioGroup: {
      iconSize: 24,
      labelSize: 28,
      size: 36,
    },
    radio: {
      iconSize: 24,
      labelSize: 28,
      size: 36,
    },
    link: {
      fontSize: 30,
      color: '#00c8c8',
    },
    modal: {
      confirmColor: '#00c8c8',
    },
    switch: {
      size: 50,
    },
    steps: {
      activeColor: '#00c8c8',
    },
    empty: {
      textSize: 28,
      iconSize: 180,
      width: 320,
      height: 320,
    },
    codeInput: {
      space: 20,
      maxlength: 4,
      fontSize: 36,
      size: 70,
    },
    readMore: {
      fontSize: 28,
      color: '#00c8c8',
    }
    // 其他组件属性配置
    // ......
  }
})
Vue.mixin(share)
app.$mount()